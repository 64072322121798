class LifeVineAccount {
    private lv: LifeVine;
    private path: string;
    private _assignments: LifeVineAssignments;
    private _bookings: LifeVineBookings;
    private _criteria: LifeVineCriteria;
    private _dependents: LifeVineDependents;
    private _locations: LifeVineLocations;

    constructor(lv: LifeVine) {
        this.lv = lv;
        this.path = '/account';
        this._assignments = new LifeVineAssignments(this.lv);
        this._bookings = new LifeVineBookings(this.lv);
        this._criteria = new LifeVineCriteria(this.lv);
        this._dependents = new LifeVineDependents(this.lv);
        this._locations = new LifeVineLocations(this.lv);
    }

    public assignments() {
        return this._assignments.pass(this.path);
    }

    public criteria() {
        return this._criteria.pass(this.path);
    }
    public bookings() {
        return this._bookings.pass(this.path);
    }

    public dependents() {
        return this._dependents.pass(this.path);
    }

    public locations() {
        return this._locations.pass(this.path);
    }

    public get() {
        return this.lv.ajax('GET', this.path);
    }

    public registerClient(data) {
        return this.lv.ajax('POST', this.path + '/register-client', data);
    }

    public registerSitter(data) {
        return this.lv.ajax('POST', this.path + '/register-sitter', data);
    }

    public save(data) {
        return this.lv.ajax('PUT', this.path, data);
    }

    public changePassword(currentPassword, newPassword) {
        return this.lv.ajax('PUT', this.path + '/change-password', {
            current_password: currentPassword,
            password: newPassword
        });
    }
}